import React, { useEffect, useState, useContext, useCallback } from 'react';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData, fetchData2 } from 'src/fetch';
import { MenuItem, Select, Button } from '@mui/material';
import { cafStatusList, aspStatusList, herStatusList } from '../statusHelper';
import Dropdown from '../../../../../components/Dropdown';

const Matching = ({ statusID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  const [tempData, setTempData] = useState();
  const [provList, setProvList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [vafProviders, setVafProviders] = useState([]);
  const [showAllProvs, setShowAllProvs] = useState(false);
  const [tempStatus, setTempStatus] = useState(null);

  const getVafProviders = useCallback(async (tempData) => {
    await fetchData(`${baseUrl}/api/admin/get-vafproviders`, 'POST', localStorage.getItem('JWT'), { company: tempData })
      .then(async (response) => {
        let resp = await response.json();
        setVafProviders(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  });

  const updateStatusStatus = async (reqID, newValue) => {
    await fetchData(`${baseUrl}/api/aspreq/status/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newValue,
    })
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const getRequestData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setTempStatus(resp.reqstatus);
        setTempData(resp);
        getVafProviders(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, statusID]);

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  console.log(tempData, 'tempData');

  const getProviderList = () => {
    let tempArray = [];
    if (tempData) {
      let provList = [];
      if (
        tempData.selectedProvGrp === 'ev' ||
        tempData.selectedProvGrp === 'office' ||
        tempData.selectedProvGrp === 'enterprise'
      ) {
        provList.push(
          ...tempData.potential_providers[0],
          ...tempData.potential_providers[1],
          ...tempData.potential_providers[2]
        );
      } else {
        provList = tempData.potential_providers;
      }

      console.log(provList, 'provList');
      provList.forEach((e) => {
        tempArray.push(e.providerName);
      });
      setProvList(tempArray);
    }
  };
  //console.log(tempData, 'tempData');
  useEffect(() => {
    tempData && getProviderList();
    tempData && setProviderList(tempData.potential_providers);
    //tempData && checkVatMatch();
  }, [tempData, providerList]);

  const updateRequestData = useCallback(
    async (statusID, updateParams) => {
      await fetchData2(`${baseUrl}/api/aspreq/${statusID}`, 'PATCH', 'B3OeO1K7-KcEcE8PdI4Eb', {
        requestId: statusID,
        updateParams: updateParams,
      })
        .then(async (response) => {
          console.log('AXIOS RESPONSE: ', response);
          reloadData();
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );

  const singleValueHandler = useCallback((event) => {
    setTempData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  });

  const getProfitMargin = (price, provPrice) => {
    //console.log(price, 'price', provPrice, 'provPrice');
    const margin = {
      marginPercent: 0,
      marginValue: 0,
    };
    if (tempData.proposedContPrice !== 'Egyéni ajánlat') {
      margin.marginValue = parseInt(price) - parseInt(provPrice);
      margin.marginPercent = Math.round(
        (100 * (parseInt(price) - parseInt(provPrice))) / ((parseInt(price) + parseInt(provPrice)) / 2)
      );
    }
    return margin;
  };

  //console.log(tempData, 'TEMPDATA IN MATCHING');

  const getVafProvidersList = useCallback(() => {
    let shortList = [];

    console.log(vafProviders, 'GET TOP PROVIDERS CALLED');

    vafProviders.forEach((e, idx) => {
      shortList.push(
        <div key={idx}>
          <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>
            {e.provider_info.companyname}
          </span>
          <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
            {e.provider_info.authorizedperson}
          </span>
          <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.provider_info.email}</span>
          <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.provider_info.phone}</span>
          <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.provider_info.city}</span>
          <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
            {e.provider_industry_experience[tempData.industry]}
          </span>
          {/* <span style={{ display: 'inline-block', width: '150px', textAlign: 'center' }}>
            {tempData.originalSinglePrice}
          </span> */}
        </div>
      );
    });
    //console.log(shortList, 'shortlist');
    return shortList;
  }, [vafProviders]);

  const getTopProviders = useCallback(() => {
    let chosenProviders = [];
    let sortedList = [];
    let shortList = [];
    if (
      tempData.selectedProvGrp === 'ev' ||
      tempData.selectedProvGrp === 'office' ||
      tempData.selectedProvGrp === 'enterprise'
    ) {
      console.log(tempData.selectedProvGrp, 'selectedGrp');
      if (tempData.selectedProvGrp === 'ev') {
        chosenProviders = tempData.potential_providers[0];
      } else if (tempData.selectedProvGrp === 'office') {
        chosenProviders = tempData.potential_providers[1];
      } else if (tempData.selectedProvGrp === 'enterprise') {
        chosenProviders = tempData.potential_providers[2];
      }
      if (showAllProvs) {
        console.log('showAllProvs');
        let allList = [];
        tempData.potential_providers.forEach((e) => {
          e.forEach((f) => {
            allList.push(f);
          });
        });
        chosenProviders = allList;
      }

      sortedList = chosenProviders.sort(
        (a, b) => parseInt(b.smsValues.superMatchScore) - parseInt(a.smsValues.superMatchScore)
      );
      sortedList.forEach((e, idx) => {
        let vatMatching = true;

        if (
          e.smsValues.vatPaying === true &&
          tempData.vatFilter === 'false' &&
          tempData.proposedContPrice !== 'Egyéni ajánlat'
        ) {
          console.log('VAT CHECK INSIDE');
          let totalPrice = 0;
          if (typeof e.contMaxPrice === 'string') {
            totalPrice = Number(e.contMaxPrice.replace('.', ''));
          } else {
            totalPrice = e.contMaxPrice;
          }

          let providerPrice = 0;
          if (typeof e.providerContPrice === 'string') {
            providerPrice = Number(e.providerContPrice.replace('.', ''));
          } else {
            providerPrice = e.providerContPrice;
          }

          console.log(providerPrice, 'prices', totalPrice);
          if (totalPrice * 0.85 < providerPrice * 1.27) {
            console.log('NEM OKES');
            vatMatching = false;
          } else {
            console.log('OKES');
            vatMatching = true;
          }
        }
        // console.log("YO THIS IS E", e);
        if (idx < 18) {
          if (tempData.proposedContPrice && tempData.proposedContPrice !== 'Egyéni ajánlat' && e.providerContPrice) {
          }

          shortList.push(
            <div key={idx}>
              <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.providerName}</span>
              <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.authorizedperson}</span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.superMatchScore}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.totalmatchscore}</span>
              <span style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>
                {e.providerContPrice}
              </span>
              {/* <span style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>{e.providerDistance}</span> */}
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerZip}</span>
              <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerCity}</span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.providerExperience}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerReview}</span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerInsurance}</span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.digitalBadge}</span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.matchScorePoint}
              </span>
              <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                {vatMatching ? 'OK' : 'NOT OK'}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.vatPaying ? 'Yes' : 'No'}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.priorityScore}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.onBoardScore}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.gotCustomerScore}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.sizeScore}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.responseScore}
              </span>
              <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                {e.smsValues.yearsInService}
              </span>
              <span style={{ display: 'inline-block', width: '150px', textAlign: 'center' }}>
                {tempData.originalSinglePrice}
              </span>
              <span style={{ display: 'inline-block', width: '300px', textAlign: 'center' }}>{e.providerEmail}</span>
              <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerPhone}</span>
            </div>
          );
        }
      });
    } else {
      if (tempData.selected_providers.length > 0) {
        console.log('fake cherry');
        let chosenProvs = tempData.selected_providers;
        chosenProvs.forEach((e, idx) => {
          if (e.packageType === 'advanced') {
            shortList.push(
              <div key={idx} style={{ backgroundColor: 'lightgrey' }}>
                <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.providerName}</span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.providerContPrice}
                </span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.providerSinglePrice}
                </span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.proposedContPrice}
                </span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.proposedSinglePrice}
                </span>
                <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.providerEmail}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerPhone}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerCity}</span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.totalmatchscore}</span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerReview}</span>
                <span style={{ display: 'inline-block', width: '150px', textAlign: 'center' }}>
                  {tempData.originalSinglePrice}
                </span>
                <span style={{ display: 'inline-block', width: '300px', textAlign: 'center' }}>{e.providerEmail}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerPhone}</span>
              </div>
            );
          } else if (e.packageType === 'premium') {
            shortList.push(
              <div key={idx}>
                <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.providerName}</span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.providerContPrice}
                </span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.providerSinglePrice}
                </span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.proposedContPrice}
                </span>
                <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                  {e.proposedSinglePrice}
                </span>
                <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.providerEmail}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerPhone}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerCity}</span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                  {e.providerExperience}
                </span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.totalmatchscore}</span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerReview}</span>
                <span style={{ display: 'inline-block', width: '150px', textAlign: 'center' }}>
                  {tempData.originalSinglePrice}
                </span>
              </div>
            );
          }
        });
      } else if (tempData.potential_providers && tempData.potential_providers[0].smsValues) {
        console.log('ELSE IF ASP');
        sortedList = tempData.potential_providers.sort(
          (a, b) => parseInt(b.smsValues.superMatchScore) - parseInt(a.smsValues.superMatchScore)
        );

        //console.log(sortedList, 'SORTED LIST');
        sortedList.forEach((e, idx) => {
          let vatMatching = true;
          if (
            e.smsValues.vatPaying === true &&
            tempData.vatFilter === 'false' &&
            tempData.proposedContPrice !== 'Egyéni ajánlat'
          ) {
            let providerPrice = 0;
            let totalPrice = 0;
            if (typeof e.totalPrice === 'string') {
              totalPrice = Number(e.totalPrice.replace('.', ''));
            } else {
              totalPrice = e.totalPrice;
            }
            if (typeof e.providerPrice === 'string') {
              providerPrice = Number(e.providerPrice.replace('.', ''));
            } else {
              providerPrice = e.providerPrice;
            }
            if (totalPrice * 0.85 < providerPrice * 1.27) {
              // console.log('NEM OKES');
              vatMatching = false;
            } else {
              //console.log('OKES');
              vatMatching = true;
            }
          }
          let margin = {
            marginPercent: 0,
            marginValue: 0,
          };
          if (idx < 18) {
            if (tempData.proposedContPrice && tempData.proposedContPrice !== 'Egyéni ajánlat' && e.providerContPrice) {
              let contPrice = 0;
              if (typeof tempData.proposedContPrice === 'string') {
                contPrice = Number(tempData.proposedContPrice.replace('.', ''));
              } else {
                contPrice = tempData.proposedContPrice;
              }
              let provPrice = 0;
              if (typeof e.providerContPrice === 'string') {
                provPrice = Number(e.providerContPrice.replace('.', ''));
              } else {
                provPrice = e.providerContPrice;
              }
              margin = getProfitMargin(contPrice, provPrice);
            }

            parseInt(margin.marginValue) <= 0 || !vatMatching
              ? shortList.push(
                  <div key={idx} style={{ backgroundColor: '#f1a6a5' }}>
                    <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>
                      {e.providerName}
                    </span>
                    <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
                      {e.authorizedperson}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.superMatchScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.totalmatchscore}
                    </span>
                    <span style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>
                      {e.providerContPrice}
                    </span>
                    <span style={{ display: 'inline-block', width: '70px', textAlign: 'center' }}>
                      {margin.marginValue}
                    </span>
                    <span
                      style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}
                    >{`${margin.marginPercent}%`}</span>
                    <span style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>
                      {e.providerDistance}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerZip}</span>
                    <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
                      {e.providerCity}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.providerExperience}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.providerReview}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.providerInsurance}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.digitalBadge}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.matchScorePoint}
                    </span>
                    <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                      {vatMatching ? 'OK' : 'NOT OK'}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.vatPaying ? 'Yes' : 'No'}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.priorityScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.onBoardScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.gotCustomerScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.sizeScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.responseScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.yearsInService}
                    </span>
                    <span style={{ display: 'inline-block', width: '150px', textAlign: 'center' }}>
                      {tempData.originalSinglePrice}
                    </span>
                    <span style={{ display: 'inline-block', width: '300px', textAlign: 'center' }}>
                      {e.providerEmail}
                    </span>
                    <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
                      {e.providerPhone}
                    </span>
                  </div>
                )
              : shortList.push(
                  <div key={idx} style={{ backgroundColor: 'lightgreen' }}>
                    <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>
                      {e.providerName}
                    </span>
                    <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
                      {e.authorizedperson}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.superMatchScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.totalmatchscore}
                    </span>
                    <span style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>
                      {e.providerContPrice}
                    </span>
                    <span style={{ display: 'inline-block', width: '70px', textAlign: 'center' }}>
                      {margin.marginValue}
                    </span>
                    <span
                      style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}
                    >{`${margin.marginPercent}%`}</span>
                    <span style={{ display: 'inline-block', width: '100px', textAlign: 'center' }}>
                      {e.providerDistance}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerZip}</span>
                    <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
                      {e.providerCity}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.providerExperience}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.providerReview}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.providerInsurance}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.digitalBadge}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.matchScorePoint}
                    </span>
                    <span style={{ display: 'inline-block', width: '80px', textAlign: 'center' }}>
                      {vatMatching ? 'OK' : 'NOT OK'}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.vatPaying ? 'Yes' : 'No'}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.priorityScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.onBoardScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.gotCustomerScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.sizeScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.responseScore}
                    </span>
                    <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                      {e.smsValues.yearsInService}
                    </span>
                    <span style={{ display: 'inline-block', width: '150px', textAlign: 'center' }}>
                      {tempData.originalSinglePrice}
                    </span>
                    <span style={{ display: 'inline-block', width: '300px', textAlign: 'center' }}>
                      {e.providerEmail}
                    </span>
                    <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
                      {e.providerPhone}
                    </span>
                  </div>
                );
          }
        });
      } else if (!tempData.potential_providers[0].smsValues && tempData.potential_providers) {
        console.log('normal match');
        sortedList = tempData.potential_providers.sort(
          (a, b) => parseInt(b.totalMatchScore) - parseInt(a.totalMatchScore)
        );

        sortedList.forEach((e, idx) => {
          if (idx < 10) {
            shortList.push(
              <div key={idx}>
                <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.providerName}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>
                  {e.authorizedperson}
                </span>
                <span style={{ display: 'inline-block', width: '250px', textAlign: 'center' }}>{e.providerEmail}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerPhone}</span>
                <span style={{ display: 'inline-block', width: '200px', textAlign: 'center' }}>{e.providerCity}</span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.totalmatchscore}</span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>
                  {e.providerExperience}
                </span>
                <span style={{ display: 'inline-block', width: '50px', textAlign: 'center' }}>{e.providerReview}</span>
                <span style={{ display: 'inline-block', width: '150px', textAlign: 'center' }}>
                  {tempData.originalSinglePrice}
                </span>
              </div>
            );
          }
        });
      }
    }
    // console.log(shortList);
    return shortList;
  }, [tempData, showAllProvs]);

  // const checkVatMatch = () => {
  //   tempData &&
  //     providerList.forEach((provider) => {
  //       console.log(provider, 'provider');
  //       if (
  //         provider.smsValues?.vatPaying === true &&
  //         (tempData.vatFilter === 'false' || !tempData.vatFilter) &&
  //         tempData.proposedContPrice !== 'Egyéni ajánlat'
  //       ) {
  //         let totalPrice = Number(tempData.proposedContPrice.replace('.', ''));
  //         let providerPrice = Number(provider.providerPrice.replace('.', ''));

  //         if (totalPrice * 0.85 > providerPrice * 1.27) {
  //           console.log('NEM OKES');
  //           provider.smsValues.isVATMatch = false;
  //         } else {
  //           console.log('OKES');
  //           provider.smsValues.isVATMatch = true;
  //         }
  //       }
  //     });
  // };
  const reloadData = async () => {
    await getRequestData();
  };

  // console.log("YO THIS IS TEMPDATA", tempData);

  return (
    <>
      {tempData ? (
        <>
          <div className="StatusDetailsLabel">
            <Dropdown
              label="CAF Státusz"
              id="cafstatus"
              list={cafStatusList}
              selValue={tempData.reqstatus.cafstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, cafstatus: value })}
            />
            <Dropdown
              label="ASP Státusz"
              id="aspstatus"
              list={aspStatusList}
              selValue={tempData.reqstatus.aspstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, aspstatus: value })}
            />
            <Dropdown
              label="REG Státusz"
              id="herstatus"
              list={herStatusList}
              selValue={tempData.reqstatus.herstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, herstatus: value })}
            />
            <div style={{ width: '150px', padding: '16px' }}>
              <Button
                onClick={() => updateStatusStatus(tempData._id, tempStatus)}
                style={{ float: 'right' }}
                variant="contained"
              >
                Státusz mentése
              </Button>
            </div>
          </div>
          <div className="matchingParent" style={{ marginTop: '24px' }}>
            <div className="matchingChild1">
              <div>
                <p style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>Ennyi könyvelőt találtunk:</p>
                <p style={{ fontSize: '24px', margin: '0 auto' }}>
                  {tempData.selectedProvGrp === '' ||
                  (tempData.selectedProvGrp === undefined && tempData.potential_providers.length !== 0)
                    ? tempData.potential_providers.length
                    : 0}
                  {tempData.selectedProvGrp === 'ev' &&
                    (tempData.potential_providers[0].length !== 0 ? tempData.potential_providers[0].length : 0)}
                  {tempData.selectedProvGrp === 'office' &&
                    (tempData.potential_providers[1].length !== 0 ? tempData.potential_providers[1].length : 0)}
                  {tempData.selectedProvGrp === 'enterprise' &&
                    (tempData.potential_providers[2].length !== 0 ? tempData.potential_providers[2].length : 0)}
                </p>
              </div>
            </div>
            <div className="matchingChild2">
              <div style={{ width: '200px' }}>Illetékes ügyintéző: </div>
              <Select
                id="responsibleagent"
                name="responsibleagent"
                onChange={singleValueHandler}
                value={tempData.responsibleagent}
                style={{ width: '200px' }}
                variant="standard"
              >
                <MenuItem value={''}>Nem hozzárendelt</MenuItem>
                <MenuItem value={'Andi'}>Andi</MenuItem>
                <MenuItem value={'Nina'}>Nina</MenuItem>
                <MenuItem value={'Orsi'}>Orsi</MenuItem>
                <MenuItem value={'Zsófi'}>Zsófi</MenuItem>
                <MenuItem value={'Dia'}>Dia</MenuItem>
                <MenuItem value={'Andris'}>Andris</MenuItem>
              </Select>
            </div>
            <div className="matchingChild3">
              <div style={{ width: '200px' }}>Bookkeepie entitás: </div>
              <Select
                id="bklegalentity"
                name="bklegalentity"
                onChange={singleValueHandler}
                value={tempData.bklegalentity}
                style={{ width: '200px' }}
                variant="standard"
              >
                <MenuItem value={'Bookkeepie.com Services Kft.'}>Bk.com Services Kft.</MenuItem>
                <MenuItem value={'Bookkeepie.com Infrastructure and Industry Kft'}>
                  Bk.com Infrastructure and Industry Kft
                </MenuItem>
                <MenuItem value={'Bookkeepie.com Health and Tech Kft.'}>Bk.com Health and Tech Kft.</MenuItem>
                <MenuItem value={'Bookkeepie.com Commerce Kft.'}>Bk.com Commerce Kft.</MenuItem>
                <MenuItem value={'Bookkeepie.com ALFA Kft.'}>Bk.com ALFA Kft.</MenuItem>
              </Select>
            </div>
            <div className="matchingChild4">
              <div style={{ width: '200px' }}>Illetékes ügyvéd: </div>
              <Select
                id="chosenlawyer"
                name="chosenlawyer"
                onChange={singleValueHandler}
                value={tempData.chosenlawyer}
                style={{ width: '200px' }}
                variant="standard"
              >
                <MenuItem value={'Hoppál Anna'}>Hoppál Anna</MenuItem>
                <MenuItem value={'Kováts Eszter'}>Kováts Eszter</MenuItem>
              </Select>
            </div>
            <div className="matchingChild5">
              <div style={{ width: '200px' }}>Illetékes szolgáltató: </div>
              <Select
                id="chosenprovider"
                name="chosenprovider"
                onChange={singleValueHandler}
                value={tempData.chosenprovider || ''}
                style={{ width: '200px' }}
                variant="standard"
              >
                {tempData.potential_providers.length > 0
                  ? provList.map((e, idx) => {
                      return (
                        <MenuItem key={idx} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })
                  : vafProviders.map((e, idx) => {
                      return (
                        <MenuItem key={idx} value={e.provider_info.companyname}>
                          {e.provider_info.companyname}
                        </MenuItem>
                      );
                    })}
              </Select>
            </div>
            <div className="matchingChild6">
              <Button
                onClick={() => {
                  updateRequestData(statusID, tempData);
                }}
              >
                Mentés
              </Button>
            </div>

            {tempData.potential_providers.length > 0 ? (
              <>
                <div className="matchingChild7">
                  <div style={{ marginBottom: '8px' }}>
                    <div style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>Üzenet Andinak:</div>
                    <p>{tempData.messageAboutSP}</p>
                  </div>
                  {tempData.selected_providers.length > 0 && (
                    <div>Listából választott könyvelő : {tempData.spSelectedFromList}</div>
                  )}

                  {tempData.selected_providers.length > 0 ? (
                    <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>Cherrypickről jött!</p>
                  ) : tempData.selectedProvGrp !== '' &&
                    tempData.selectedProvGrp !== null &&
                    tempData.selectedProvGrp !== undefined ? (
                    <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>ASPről jött TOP3!</p>
                  ) : (
                    <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>ASPről jött!</p>
                  )}
                  {tempData.selectedProvGrp !== '' && (
                    <div>
                      <button onClick={() => setShowAllProvs(!showAllProvs)}>
                        {showAllProvs ? 'Hide All Providers' : 'Show All Providers'}
                      </button>
                    </div>
                  )}
                  {tempData && tempData.potential_providers[0]?.smsValues ? (
                    <>
                      <div style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>
                        Top Providers, Super Match Score:
                      </div>
                      <div style={{ fontWeight: '700', height: '150px', display: 'flex', alignItems: 'center' }}>
                        <span className="rotatedtext" style={{ width: '250px' }}>
                          Provider Name
                        </span>
                        <span className="rotatedtext" style={{ width: '200px' }}>
                          Authorized Person
                        </span>
                        <span className="rotatedtext" style={{}}>
                          SuperMatchScore
                        </span>
                        <span className="rotatedtext" style={{}}>
                          MatchScore
                        </span>
                        <span className="rotatedtext" style={{ width: '100px' }}>
                          Price
                        </span>
                        <span className="rotatedtext" style={{ width: '70px' }}>
                          Margin
                        </span>
                        <span className="rotatedtext" style={{ width: '50px' }}>
                          Margin%
                        </span>
                        <span className="rotatedtext" style={{ width: '100px' }}>
                          Distance
                        </span>
                        <span className="rotatedtext" style={{}}>
                          ZIP
                        </span>
                        <span className="rotatedtext" style={{ width: '200px' }}>
                          City
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Industry Experience
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Reviews
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Insurrance
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Badge
                        </span>
                        <span className="rotatedtext" style={{}}>
                          SuperMatchPoint
                        </span>
                        <span className="rotatedtext" style={{ width: '80px' }}>
                          VAT match?
                        </span>
                        <span className="rotatedtext" style={{}}>
                          VAT
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Priority
                        </span>
                        <span className="rotatedtext" style={{}}>
                          ASP onboard?
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Got customer?
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Size match
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Response time
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Years in service
                        </span>
                        <span className="rotatedtext" style={{ width: '150px' }}>
                          Original Single Price
                        </span>
                        <span className="rotatedtext" style={{ width: '300px' }}>
                          Provider Email
                        </span>
                        <span className="rotatedtext" style={{ width: '200px' }}>
                          Provider Phone
                        </span>
                      </div>
                      <div style={{ overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                        {getTopProviders()}
                      </div>
                    </>
                  ) : tempData.potential_providers[0][0]?.smsValues ? (
                    <>
                      <div style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>
                        Top Providers, Super Match Score:
                      </div>
                      <div style={{ fontWeight: '700', height: '150px', display: 'flex', alignItems: 'center' }}>
                        <span className="rotatedtext" style={{ width: '250px' }}>
                          Provider Name
                        </span>
                        <span className="rotatedtext" style={{ width: '200px' }}>
                          Authorized Person
                        </span>
                        <span className="rotatedtext" style={{}}>
                          SuperMatchScore
                        </span>
                        <span className="rotatedtext" style={{}}>
                          MatchScore
                        </span>
                        <span className="rotatedtext" style={{ width: '100px' }}>
                          Price
                        </span>
                        {/* <span className="rotatedtext" style={{ width: '100px'}}>Distance</span> */}
                        <span className="rotatedtext" style={{}}>
                          ZIP
                        </span>
                        <span className="rotatedtext" style={{ width: '200px' }}>
                          City
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Industry Experience
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Reviews
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Insurrance
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Badge
                        </span>
                        <span className="rotatedtext" style={{}}>
                          SuperMatchPoint
                        </span>
                        <span className="rotatedtext" style={{ width: '80px' }}>
                          VAT match?
                        </span>
                        <span className="rotatedtext" style={{}}>
                          VAT
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Priority
                        </span>
                        <span className="rotatedtext" style={{}}>
                          ASP onboard?
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Got customer?
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Size match
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Response time
                        </span>
                        <span className="rotatedtext" style={{}}>
                          Years in service
                        </span>
                        <span className="rotatedtext" style={{ width: '150px' }}>
                          Original Single Price
                        </span>
                        <span className="rotatedtext" style={{ width: '300px' }}>
                          Provider Email
                        </span>
                        <span className="rotatedtext" style={{ width: '200px' }}>
                          Provider Phone
                        </span>
                      </div>
                      <div style={{ overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                        {getTopProviders()}
                      </div>
                    </>
                  ) : tempData.selected_providers.length > 0 ? (
                    <>
                      <div style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>
                        Top Providers, cherry Match:
                      </div>
                      <div
                        style={{
                          fontWeight: '700',
                          height: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <span style={{ display: 'inline-block', width: '250px' }}>Name</span>
                        <span style={{ display: 'inline-block', width: '80px' }}>Provider Price</span>
                        <span style={{ display: 'inline-block', width: '80px' }}>Provider Single</span>
                        <span style={{ display: 'inline-block', width: '80px' }}>Customer Price</span>
                        <span style={{ display: 'inline-block', width: '80px' }}>Customer Single</span>
                        <span style={{ display: 'inline-block', width: '250px' }}>Provider E-mail</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>Provider Phone</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>City</span>
                        <span style={{ display: 'inline-block', width: '50px' }}>XP</span>
                        <span style={{ display: 'inline-block', width: '50px' }}>MS</span>
                        <span style={{ display: 'inline-block', width: '50px' }}>Review</span>
                        <span style={{ display: 'inline-block', width: '150px' }}>Original Single Price</span>
                      </div>
                      <div style={{ overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                        {getTopProviders()}
                      </div>
                    </>
                  ) : tempData.companytype !== '' ? (
                    <>
                      <div style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>
                        Top Providers, normal Match:
                      </div>
                      <div
                        style={{
                          fontWeight: '700',
                          height: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <span style={{ display: 'inline-block', width: '250px' }}>Name</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>Authorized Person</span>
                        <span style={{ display: 'inline-block', width: '250px' }}>Provider E-mail</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>Provider Phone</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>City</span>
                        <span style={{ display: 'inline-block', width: '50px' }}>MS</span>
                        <span style={{ display: 'inline-block', width: '50px' }}>XP</span>
                        <span style={{ display: 'inline-block', width: '30px' }}>Review</span>
                        <span style={{ display: 'inline-block', width: '150px' }}>Original Single Price</span>
                      </div>
                      <div style={{ overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                        {getTopProviders()}
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>VAF Match:</div>
                      <div
                        style={{
                          fontWeight: '700',
                          height: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <span style={{ display: 'inline-block', width: '250px' }}>Name</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>Authorized Person</span>
                        <span style={{ display: 'inline-block', width: '250px' }}>Provider E-mail</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>Provider Phone</span>
                        <span style={{ display: 'inline-block', width: '200px' }}>City</span>

                        <span style={{ display: 'inline-block', width: '50px' }}>XP</span>
                      </div>
                      <div style={{ overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                        {getVafProvidersList()}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="matchingChild7">
                  <div style={{ fontWeight: '700', fontSize: '20px', color: '#2065D1' }}>VAF all providers</div>
                  <div
                    style={{
                      fontWeight: '700',
                      height: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <span style={{ display: 'inline-block', width: '250px' }}>Name</span>
                    <span style={{ display: 'inline-block', width: '200px' }}>Authorized Person</span>
                    <span style={{ display: 'inline-block', width: '250px' }}>Provider E-mail</span>
                    <span style={{ display: 'inline-block', width: '200px' }}>Provider Phone</span>
                    <span style={{ display: 'inline-block', width: '200px' }}>City</span>
                    <span style={{ display: 'inline-block', width: '50px' }}>XP</span>
                    <span style={{ display: 'inline-block', width: '150px' }}>Original Single Price</span>
                  </div>
                  <div style={{ overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                    {getVafProvidersList()}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div style={{ marginTop: '16px' }}>Loading...</div>
      )}
    </>
  );
};

export default Matching;
